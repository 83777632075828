.mydata-page,
.mystats-page,
.recomended-page {
  display: block;
  margin: auto;
  width: 75%;
}

.modal-scroll {
  max-height: 430px;
  overflow: auto;
}

.mydata-page section,
.mystats-page section {
  margin-bottom: 48px;
}

.mydata-profile {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-bottom: 48px;
}

.mydata-profile-info {
  display: flex;
  flex-direction: column;
}

.mydata-profile-info h4 {
  font-size: 18px;
  font-weight: 700;
}

.mydata-profile-info p {
  font-size: 14px;
}

.mydata-stats {
  text-align: left;
}

.mydata-stats h4 {
  font-size: 20px;
  font-weight: 600;
}

.stats-card {
  padding: 16px;
  background-color: #f4f4f4;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.stats-card-title {
}

.stats-card-info {
  font-size: 10px;
}

.stats-card-value {
  font-size: 20px;
  color: #ff3b30;
  font-weight: 700;
}

.stats-card-value span {
  font-size: 12px;
}

.stats-card-value span {
  font-size: 14px;
  font-weight: 400;
}

.stats-card-img {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 48px;
  max-height: 48px;
}

.stats-card-wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}

.flex-fill {
  flex: 1;
}

.stats-card-content {
  position: relative;
  padding: 16px 0;
}

.top-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0;
}

.bottom-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  margin: o;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

}

.app-body {
  flex: 1 1 auto;
  overflow: auto;
  padding: 20px;
}

@media screen and (min-width: 1400px) {
  .stats-card-wrap {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1400px) and (min-width: 998.2px) {
  .stats-card-wrap {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 998.2px) and (min-width: 720px) {
  .stats-card-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 720px) {
  .stats-card-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

.mydata-carousel-wrap {
  display: flex;
  gap: 8px;
  position: relative;
}

.mydata-carousel {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.mydata-carousel-btn {
  flex: 0 0 40px;
  outline: none;
  border: none;
  background-color: #fff !important;
  text-align: center;
  color: #797979;
  font-size: 32px;
}

.mydata-carousel-btn-edit {
  position: absolute;
  top: -68px;
  width: 64px;
  height: 64px;
  right: 0;
}

.mydata-carousel-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  min-width: 131px;
  padding: 16px;
  flex: 1 1 131px;
}

.mydata-carousel-card-value {
  color: #ff3b30;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 12px;
}

.mydata-carousel-card-value span {
  font-size: 12px;
}

.mydata-carousel-card-img {
  width: auto;
  height: auto;
  display: block;
  margin: auto;
  gap: 4px;
}

.my-data-carosel-blank {
  visibility: hidden;
  padding: 0;
}

.card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;

}

.mydata-page .btn {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  height: 44px;
}

.mydata-page .btn p {
  flex: 1 1 auto;
  text-align: left;
  margin: 0;
}

.white {
  color: #fff;
}

.mydata-page .btn span {
  flex: 0 0 auto;
}

.mydata-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

/* .mydata-form-item {
  display: flex;
  flex-direction: column;
} */

.mydata-form-item label {
  margin-bottom: 5px;
  text-align: left;
}

.mydata-form-item input,
.mydata-form-item select {
  width: calc(100% - 16px); 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: #fff;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 24px;
}

.radio-group label {
  margin-right: 10px;
}

.mydata-form-item input[type="radio"] {
  margin-right: 5px;
}

.mydata-page .checkbox-group {
  /* display: flex; */
  flex-wrap: wrap;
  text-align: left;
}

.mydata-page .checkbox-pair {
  flex: 0 0 calc(50% - 10px);
  margin-right: 10px;
  margin-bottom: 24px;
}

.mydata-page .checkbox-group input[type="checkbox"] {
  display: none;
}

.mydata-page .checkbox-group label.checkbox-label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
}

.mydata-page .checkbox-group label.checkbox-label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #797979;
  border-radius: 5px;
  background-color: #fff;
  padding: 2px;
  box-sizing: border-box;
}

.mydata-page
  .checkbox-group
  input[type="checkbox"]:checked
  + label.checkbox-label:before {
  background-color: #ff3b30;
  border-radius: 5px;
  border-color: #ff3b30;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.84);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-collapse {
  background-color: #007bff !important;
  border-radius: 8px !important;
  margin-bottom: 48px;
  text-align: left;

}

.custom-collapse .ant-collapse-header-text {
  color: #fff;
}

.custom-collapse .ant-collapse-expand-icon svg {
  fill: #fff !important;
}

.custom-collapse .ant-collapse-content {
  border: 1px solid #E0E0E0;
}

.custom-collapse p {
  font-size: 16px;
  color: #FF3B30;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-1 {
  margin-top: 10px;
}

.rfc-chip-data-container {
  span {
    display: block;
        padding: 4px 12px;
        border: solid 1px #dbdbdb;
        background-color: #f1f1f1;
        font-size: 14px;
        margin: 8px 0;
        border-radius: 4px;
        text-align: left;
  }
}
.rfc-title-text {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin: 12px 0;
}
.rfc-mydata-field-group input, .rfc-mydata-field-group select {
  width: 100% !important;
}

.fitness-test {
  padding: 24px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}

.fitness-test .stats-card-value {
  font-size: 20px;
}
.rfc-info-container {
  text-align: right;
}
.rfc-info-container i {
  cursor: pointer;
  color: #0069d9 !important;
  font-size:16px;
}
.rfc-info-container i:hover { 
  color: #0461c4 !important;
}
.rfc-popover-container {
  max-width: 350px;
}
 .rfc-popover-container button {
  position:absolute;
    top: 8px;
    border: 0;
    right: 10px;
 }
  .rfc-popover-container .ant-popover-inner-content {
  padding-top: 24px !important;
 }
.rfc-popover-container .ant-popover-inner-content {
  /* Your custom styles here */
  padding: 10px;
  border-radius: 4px;
}
.select:focus {
  outline: 2px solid #4d90fe; /* or any color that matches your design */
}