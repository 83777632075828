#modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
  width: 70%;
  margin: 0px auto;
}
#modalPassword {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  width: 70%;
  margin: 0px auto;
}

.custom-modal {
  border-radius: 8px;
  overflow: auto !important;
}

.custom-modal .ant-modal-content {
  border-radius: 8px;
}

.custom-modal .ant-modal-header {
  background-color: #2C2C2C;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
}

.custom-modal .ant-modal-close-x svg {
  fill: #fff;
}

.custom-modal .ant-modal-title {
  color: #fff;
  font-size: 20px;
}

.custom-modal .ant-modal-footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.custom-modal-footer {
  text-align: center;
}

.checkbox-pair input {
  width: 100% !important;
  margin-right: 0 !important;
}

.ant-modal
.checkbox-pair {
  margin-right: 0 !important;
}

@media screen and (min-width: 840px) {
  #modal {
    width: 40%;
    height: 50%;
    margin: 0px auto;
  }
  #modalPassword {
    width: 50%;
    height: 70%;
    margin: 0px auto;
  }
}

@media screen and (min-width: 1150px) {
  #modal {
    width: 30%;
  }
  #modalPassword {
    width: 30%;
    height: 70%;
  }
}
