.card_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

@media screen and (min-width: 1000px) {
  .card_grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
li:hover {
  color: darksalmon;
  cursor: pointer;
}
/* li {
  width: 100%;
} */
.top-bar {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.top-bar-category {
  width: 30%;
  margin: 10px;
  float: left;
}
.top-bar-search {
  text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.select-custom {
  width: 100%;
  margin-top: 10px;
  background: #ececec;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  padding: 10px;
}

.top-bar-search input {
  border: 1px solid #C2C2C2;
  height: 44px;
  padding: 5px 10px;
  border-radius: 8px;
}

.custom-input {
  position: relative;
}

.custom-input button {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 10px;
}

.custom-input button svg {
  fill: #797979;
  width: 18px;
  height: 18px;
}

.dropdown-new {
  height: 44px;
  border: 1px solid #C2C2C2;
  padding: 5px 10px;
  border-radius: 8px;
  background-image: url('../Images/down-arow1.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding-right: 30px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}